<template>
  <div>
    <div class="detection_list_wrap">
      <ul class="item_list_wrap">
        <li class="list_item" v-for="item in itemData" :key="`item${item.i_idx}`">
          <div class="thumbnail">
            <img :src="returnThumbnail(item)" @click="moveDetail(item.i_sku)" alt="">
            <div class="card_category cc_category">{{ item.ic_name_eng }}</div>
            <el-checkbox @change="selectItem(item.i_idx, $event)" :checked="selectedItems.indexOf(item.i_idx) > -1">
            </el-checkbox>
          </div>
          <div class="card_detail" @click="moveDetail(item.i_sku)">
            <div class="card_title">{{item.i_name}}</div>
            <div class="card_sub">{{numFormat(item.drCnt)}}Match</div>
          </div>
        </li>

        <!--       아래는 퍼블용입니다 !!-->
<!--        <li class="list_item" v-for="item in 12" :key="`item${item.i_idx}`">
          <div class="thumbnail">
            <img src="@/assets/image/main/award_temp_img1.jpg" alt="">
            <div class="card_category cc_category">Art</div>
            <el-checkbox @change="selectItem(item.i_idx, $event)" :checked="selectedItems.indexOf(item.i_idx) > -1">
            </el-checkbox>
          </div>
          <div class="card_detail" @click="moveDetail(item.i_idx)">
            <div class="card_title">병원 로고 디자인 의뢰</div>
            <div class="card_sub">14Match</div>
          </div>
        </li>-->
      </ul>
    </div>

  </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "ItemListDetectionItemLayout",
    mixins: [imageOption, imageResizeMixins],
    components: {},
    inject: ['setSelectItems'],
    provide() {
        return {}
    },
    props: {
        itemData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
            selectedItems: [],
            showList: true,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                //return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(279, null, null)}`;
                return this.setFitWidthImg(data.ItemFile.Item, 279);
            }
        },
        numFormat(num) {
            return util.isEmpty(num) || isNaN(num) ? '-' : util.Number.numFormat(num);
        },
        moveDetail(url) {
            this.$router.push(`${this.$route.path}/${url}`);
        },
        selectItem(value, checked) {
            if(checked && this.selectedItems.indexOf(value) < 0) {
                this.selectedItems.push(value);
            }else if(!checked && this.selectedItems.indexOf(value) > -1){
                this.selectedItems.splice(this.selectedItems.indexOf(value), 1);
            }
            this.setSelectItems(this.selectedItems);


        },
    },
    watch: {
        'itemData': {
            deep: true,
            handler: function (val, oldVal) {
                this.selectedItems = [];
                this.setSelectItems(this.selectedItems);
                this.showList = false;
                this.$nextTick(() => {
                    this.showList = true;
                })
            }
        }
    },
}
</script>

<style scoped>

</style>
